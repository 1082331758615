//
// Range slider
//

.vue-slide-bar-tooltip {
  background-color: $primary !important;
  border: 1px solid $primary !important;
}

.vue-slide-bar-process {
  background-color: $primary !important;
}

.vue-slide-bar {
  background-color: $gray-300 !important;
}

.vue-slide-bar-separate-text {
  color: $gray-400;
}