/*my css*/
.opt-main .opt_selection{
    width: 50%;
}
.opt-main .check_new_item{
    margin-left: auto;
}
.swal2-styled.swal2-confirm{
    background-color: #556ee6 !important;
}
.print-invoice-table .table-bordered, .print-invoice-table .table-bordered td{
    // border: 1px solid #000 !important;
    margin-top: 20px;
}
.print-invoice-table .restaurant-Listing,.print-invoice-table .restaurant-Listing th,.print-invoice-table .restaurant-Listing td{
    border: none !important;
    margin-top: 20px;
    font-size: x-large !important;
    color: #000 !important;
}
.print-invoice-table .restaurant-Listing tbody{
    align: center;
}
.call-center-item{
    justify-content: space-between;
}
.call-center-item .task-box{
    padding: 0;
    width: 48.5%;
}
.call-center-item .menu-items{
    height: auto !important;
}
.call-center-item .non-veg{
    left: 60px !important;
}
.call-center-item .veg{
    left: 60px !important;
}
.call-center-item .catalog-task-status{
    margin-right: 0 !important;
}
.call-center-item .list-available{
    margin-right: 0 !important;
}
.call-center-item .list-inline{
    margin-bottom:0 !important;
}
.finish-order-btn{
    background-color: rgb(85, 110, 230);
    border-color: rgb(85, 110, 230);
    color: white;
    cursor: pointer;
    box-sizing: border-box;
    border-width: 2px;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 12px;
    min-width: 140px;
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    line-height: 1.42857;
    border-radius: 4px;
}
.wizard-footer-right button{
    position: absolute;
    top: -60px;
    right: 5px;
}
.item-new-add .qty-item{
    padding-left: 6px;
    padding-top: 2px;
    background-color: #fff;
    color: #000;
}
.popup {
  display: none;
  position: absolute;
  padding: 10px;
  min-width: 300px;
  right: 106px;
  height: auto;
  background: #FFF;
  border: 1px solid #ccc;
  z-index: 20;
}

#popup:after {
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  z-index: -2;
}

#popup:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #FFF;
  z-index: -1;
}
.item-new-add{
    border: 1px solid #556ee6;
    border-radius: 7%;
}
.item-new-add .qty-item-add{
     border-color: #556ee6;
    color: white;
    padding: 5px 20px 6px 20px;
    border: none;
    cursor: pointer;
    color: white;
    background-color: #556ee6;
}
.item-new-add span{
    width: 22px;
    height: 22px;
    background: var(--primary);
    color: #fff;
}
.item-new-add input{
    height: 22px !important;
    width: 25px !important;
    text-align: center !important;
    font-size: 14px !important;
    border: none !important;
    border-radius: 0 !important;
    display: inline-block !important;
    vertical-align: middle !important;
}
.add-addreses .change-address{
    border-radius: 5px;
    border: none;
    height: 29px;
    cursor: pointer;
    color: white;
    background-color: #556ee6;
    transform: translateX(2px);
}
#addNewAddress .modal-body .close{
  font-size: 15px;
  left: 0;
  padding-top: 13px;
}
#addNewAddress .modal-body .close:hover{
  color: #000;
}
#addNewAddress .modal-body .close:hover {
  color: #495057 !important;
}
#addNewAddress .modal-body .close {
  color: #495057 !important;
}
.google-addnew-address .search-icon {
    font-size: 12px;
    position: absolute;
    left: 13px;
    top: 0;
    line-height: 38px;
    color: #b2b2b2;
}
.google-addnew-address .form-control{
    padding-left: 34px;
    padding-top: 9px;
}
.pac-container {
  z-index: 1200 !important;
}
#scaleModal .modal-body .close{
  font-size: 15px;
  left: 0;
  padding-top: 13px;
}
#scaleModal .modal-body .close:hover{
  color: #000;
}
#scaleModal .modal-body .close:hover {
  color: #495057 !important;
}
#scaleModal .modal-body .close {
  color: #495057 !important;
}
.pac-container {
  z-index: 1200 !important;
}
#scaleModal .modal-content .modal-header{
  border: none;
}
#scaleModal .modal-body .close{
  font-size: 15px;
  left: 0;
  padding-top: 13px;
}
#scaleModal .modal-body{
  height: auto;
}
#scaleModal .modal-body .close:hover{
  color: #000;
}
#scaleModal .modal-body .search-box{
    width: 85%;
}
.set-tag label{
    padding: 0px 5px 1px 6px;
}
#scaleModal .modal-body .search-box input{
    height: 40px;
    padding-left: 50px;
    font-size: 15px;
    font-weight: 200;
}
#scaleModal .modal-body .search-box i{
  left: 15px;
  color: #d7d7d7;
  font-size: 15px;
}
#scaleModal .modal-body .search-box input::placeholder{
  color: #d7d7d7;
}
#scaleModal .modal-body .close:hover {
  color: #495057;
}
#scaleModal .modal-body .btn-secondary:hover {
  background-color: #fff;
  border-color: #fff;
}
.address-new-btn button{
    padding: 5px 20px 6px 22px;
    position: absolute;
    border-radius: 5px;
    z-index: 2;
    border: none;
    top: 3px;
    height: 29px;
    cursor: pointer;
    color: white;
    background-color: #556ee6;
    transform: translateX(2px);
}
.buttonIn {
    width: 300px;
    position: relative;
}
  
.buttonIn button {
    position: absolute;
    border-radius: 5px;
    right: 19px;
    z-index: 2;
    border: none;
    top: 3px;
    height: 29px;
    cursor: pointer;
    color: white;
    background-color: rgb(85, 110, 230);
    transform: translateX(2px);
}
.fix-cardheader .card-header{
    min-height: 60px;
    display: flex;
    align-items: center;
}
.fix-cardheader h6{
    margin-bottom: 0;
    padding-top: 0 !important;
}
.fix-cardheader label{
    margin-bottom: 0;
}
.fix-cardheader .form-group{
    margin-bottom: 0;
}
.item-report{
    display: block;
    margin-left: auto;
    margin-right: 15px;
}
body[data-topbar=dark] .no-gutters .col a i{
    color: #7f8387;
}
.category-search .row{
    align-items: flex-end;
    flex-wrap: nowrap;
    justify-content: flex-end;
}
.category-search .d-flex.justify-content-between.align-items-end {
    flex-wrap: wrap;
}
.category-search .row .search-box {
    margin-right: 15px;
}
.category-search .row .search-box > div {
    width: 100%;
}
.b-card-whats-new{
    margin-top: 25px;
    margin-left: 22px;
    margin-right: 22px;
}
.b-card-whats-new .whats-new-title{
    border-bottom: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
}
.b-card-whats-new .whats-new-title ul{
    margin-bottom: 3px;
    padding: 3px;
}
.b-card-whats-new .whats-new-title ul li{
    margin-left: 35px;
}
.whats-new-title h1{
    font-size: 15px;
    margin: 0px 1px 5px;
}
.whats-new-title .sub-con-list{
    padding-top: 12px;
    margin-bottom: 30px;
    margin-left: 35px;
}
.whats_new{
    margin-right: -227px;
}
.whats_new .noti-icon img{
    width: 7%;
    margin-top: -5px;
}
.v-avg-rating-report{
    width: 17%;
}
.v-avg-report-date{
    padding-left: 9px;
    margin-right: -15px;
}
.v-driver-report-date{
    margin-right: -16px;
}
.tax-report{
    width: 17%;
}
.tax-report:first-child{
    padding-left: 13px;
} 
.tax-report:last-child {
    width: 25%;
}
.tax-report:last-child .form-group .date-range-list .vue-daterange-picker .reportrange-text {
    padding:0 10px!important;
    display: flex;
    align-items: center;
}
.total-sales-report-earning{
    width: 18%;
}
.total-sales-report-earning:first-child{
    padding-left: 13px;
} 
.total-sales-report-earning:last-child {
    width: 23%;
}
.total-sales-report-earning:last-child .form-group .date-range-list .vue-daterange-picker .reportrange-text {
    padding:0 10px!important;
    display: flex;
    align-items: center;
}
.main-sales-report{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-right: 36px;
}
.main-sales-report .v-sales-report{
    margin-right: 15px;
}
.order-assign-m table thead th:first-child{
    width: 140px;
    text-align: center;
}
.order-assign-m table tbody td:first-child{
    text-align: center;
}
.hor-btn-side-feedback{
    display: none !important;
}
.hor-btn-side-feedback .side-btn{
    margin-left: 5px;
    margin-top: -6px !important;
}
body[data-sidebar=dark].vertical-collpsed .btn-side-feedback .side-btn,body[data-topbar="light"].vertical-collpsed .btn-side-feedback .side-btn{
    font-size: 0;
    background: none;
    color: #6a7187;
}
body[data-sidebar=dark].vertical-collpsed .btn-side-feedback,body[data-topbar="light"].vertical-collpsed .btn-side-feedback{
    padding: 0 11px;
}
body[data-sidebar=dark].vertical-collpsed .btn-side-feedback a,body[data-topbar="light"].vertical-collpsed .btn-side-feedback a{
    margin-top: 3px;
}
body[data-sidebar=dark].vertical-collpsed .btn-side-feedback .side-btn i:hover,body[data-topbar="light"].vertical-collpsed .btn-side-feedback .side-btn i:hover{
    color: #ffffff;
}
body[data-sidebar=dark].vertical-collpsed .btn-side-feedback .side-btn i,body[data-topbar="light"].vertical-collpsed .btn-side-feedback .side-btn i{
    font-size: 1.45rem;
    margin-top: 15px;
}
body[data-sidebar=dark] .btn-side-feedback .side-btn,body[data-topbar="light"] .btn-side-feedback .side-btn{
    margin-top: 10px;
}
body[data-topbar=dark].vertical-collpsed .btn-side-feedback .side-btn{
    font-size: 0;
    background: none;
    color: #6a7187;
}
body[data-topbar=dark].vertical-collpsed .btn-side-feedback{
    padding: 0 11px;
}
body[data-topbar=dark].vertical-collpsed .btn-side-feedback a{
    margin-top: 3px;
}
body[data-topbar=dark].vertical-collpsed .btn-side-feedback .side-btn i:hover{
    color: #556ee6;
}
body[data-topbar=dark].vertical-collpsed .btn-side-feedback .side-btn i{
    font-size: 1.45rem;
    margin-top: 15px;
}
body[data-topbar=dark] .btn-side-feedback .side-btn{
    margin-top: 10px;
}
body[data-topbar=dark].vertical-collpsed .btn-side-feedback .side-btn{
    font-size: 0;
    background: none;
    color: #6a7187;
}
body[data-topbar=dark] .btn-side-feedback .side-btn{
    background-color: #545a6d;
    color: #ffffff;
} 
body[data-sidebar-size=small] .btn-side-feedback .side-btn{
    margin-top: 10px;
}
.btn-side-feedback {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 15px;
}
.btn-side-feedback .side-btn{
    position: relative;
    height: 25px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-color: #ffffff ;
}
.btn-side-feedback .side-btn i{ 
    font-size: 1.25rem;
}
.order-action-btn .dropdown ul{
    transform: translate3d(-26px, -46px, 0px) !important;
}
.cst-swtch-set{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    justify-content: flex-end;
}
.cst-small-swtch .switch{
    width: 40px;
    height: 20px;
}
.cst-small-swtch .switch .slider::before{
    height: 13px;
    width: 13px;
    left: 3px;
    bottom: 4px;
}
.cst-small-swtch{
    margin-right: 25px !important;
}
.cst-small-swtch input.switch-on:checked + .slider::before{
    transform: translateX(21px);
}
.cst-swtch-set .list-available{
    margin-bottom: 2px;
}
.add-btn{
    display: block;
}
.m-add-btn{
    display: none;
}
.m-export-btn{
    display: none;
}
.export-btn{
    display: block;
}
.big-swtch .form-group .bv-no-focus-ring .switch {
    width: 70px !important;
    margin-bottom: 0 !important;
}
.big-swtch .form-group .bv-no-focus-ring .switch .slider::before {
    left: 4px !important;
    height: 17px;
    width: 17px;
    bottom: 5px !important;
}
.big-swtch .form-group .bv-no-focus-ring .switch .off {
    left: 63% !important;
}
.big-swtch .form-group .bv-no-focus-ring .switch .on {
    left: 40% !important;
    top: 49%;
}   
.big-swtch .form-group .bv-no-focus-ring .switch input.switch-on:checked + .slider::before {
    left: 21px !important;
bottom: 5px !important;
}
.vendr-switch .switch{
    height: 27px !important;
    width: 55px !important;
    margin-top: 4px;
}
.vendr-switch input.switch-on:checked + .slider::before{
    left:6px !important;
    bottom: 5px !important;
    height: 17px !important;
    width: 17px !important;
}
.vendr-switch {

}
.vendr-switch .slider::before {
    left: 5px !important;
    bottom: 5px !important;
    height: 17px !important;
    width: 17px !important;
}
.vendr-switch .switch span{
    line-height: 16px;
}   
.addcust-tbl thead tr th:first-child{
    width: 42%;
}
.addcust-tbl thead tr th:nth-child(2){
    width: 18%;
}
.addcust-tbl thead tr th:nth-child(3),.addcust-tbl thead tr th:nth-child(4){
    width: 10%;
}
.ctmz-accordion .card-body{
    padding-left: 0;
    padding-right: 0;
}
.card-header {
  background-color: #b3b0b033 !important;
  border-bottom: 0 solid #b3b0b033 !important;
}
.addcust-tbl tbody tr td {
    vertical-align: middle;
}
.operation-time .table tbody tr td:first-child,.operation-time .table tbody tr td:last-child {
    width: 15%;
}
.operation-time .table tbody tr td:nth-child(2),.operation-time .table tbody tr td:nth-child(3) {
    /* width:35%; */
}
.status-switch .col-md-6:first-child{
        padding-right: 0;
}
.whole-main{
    margin: 20px 0;
}
.timeslt{
    margin-bottom: 20px;
}
.status-switch .col-md-6:last-child{
        padding-left: 0;
}
.rdio-btns {
    margin-bottom: 20px;
}
.cstm-switch .switch{
    margin-bottom: 0 !important; 
}
.newset-media {
    float: right;
}
.list-available {
    float: right;
    margin-bottom: 7px;
    margin-right: 7px;
}
.account-main{
    margin-top: -35px;
}
.home-main{
    float: right;
}
.btn-group .user-profile-main{
    margin-left: 10px;
}
/* .navbar-brand-box img{
    margin-left: -10px;
    width: 60%;
} */
.horizontal-image .navbar-brand-box{
    background: none !important;
}
#topnav-menu-content .nav-link{
    padding: 1rem 0.55rem;
}
#topnav-menu-content .nav-item:first-child .nav-link{
    padding-left: 0 !important;
}
.toasted.toasted-primary.success {
    color: #1b654a;
    background-color: #d6f3e9 !important;
    border-color: #c6eee0;
    padding: .55rem 1.25rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    font-size: 13px !important;
    font-weight: 400 !important;
}
.toasted.toasted-primary.error {
    color: #7f3737;
    background-color: #fde1e1 !important;
    border-color: #fcd5d5;
    padding: .55rem 1.25rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    font-size: 13px !important;
    font-weight: 400 !important;
}
.location-status{
    max-width: 360px !important;
}
.form-control::-webkit-input-placeholder {
    color: rgba(0,0,0,0.3);
}

.form-control:-ms-input-placeholder {
    color: rgba(0,0,0,0.3);
}

.form-control::placeholder {
    color: rgba(0,0,0,0.3);
}
.form-group label{
    color: rgba(0,0,0,0.6);
    font-weight: 600;
}
.custom-tab {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}
.custom-tab .nav-tabs{
    background: #f8f8fb;
}
/* .newcardbody .nav-tabs .nav-link.active{
    background: #f8f8fb;
} */
.tabs-main .nav-pills{
    width: 30%;
    margin : 0 auto;
    float: left;
}
.main-tab{
    margin-top:36px;
}
.main-tab .multiselect{
    width: 50%;
}
.card-img-top {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 170px;
}
.mycard-list .card-active{
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);
    transform: scale(1.05);
    border-radius: 10px;
    overflow: hidden;
}
.mycard-list .card-active:hover{
    transform: none;
}
.mycard-list .card{
    border-radius: 10px;
    overflow: hidden;
}
.mycard-list .card:hover{
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);
    transform: scale(1.05);
    transition: 0.2s;
}
.mycard-body{
    padding-right: 10px;
}
.vendor-main{
    display: flex;
    align-items: center;
    justify-content: space-between; 
    padding-top: 20px;

}
.vendor-main .custom-control{
    display: flex;
    align-items: center;
}
.vendor-main p{
    margin-bottom: 0;
    margin-left: 15px;
    font-weight: 600;
    font-size: 22px;
}
.vendor-main .search-box{
    width: 100%;
}
/* .status-main .not-available label{
    text-decoration: line-through;
} */
.body[data-sidebar=dark] #sidebar-menu ul li a{
    color: #fff;
}
.faq-info{
    width:100%;
}
.rating{
    color: #f2b01e;
}
.category-badge{
    font-size: 12px;
}
.order-main{
    margin-left: 15px;
    border-bottom: 2px solid #ced4da;
}

.order-tabs .nav-link {
    display: block;
    padding: 0.5rem 0.5rem;
}
.rating-main{
    margin-top: 30px;
}
.date-main{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}
.date-main .mx-datepicker{
    width: 220px !important;
    margin-right: 20px;
}
.customer-tabs .nav-tabs-custom{
    width: 30%;
    margin : 0 auto;
    float: left;
}
.push-main{
    width: 173px;
}
.mylist-main {
    width: 10.5%;
    margin: 10px;
}
.mylist-main .card{
    height: 100%;
}
.mylist-main .card-title{
    font-size: 10px;
}
.mylist-main .card .card-body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
}
.mylist-main .card-text{
    font-size : 10px;
}
.mylist-main .card .card-body a{
    width: 100%;
}
.mylist-main .card .card-body span{
    margin-bottom: 8px;
}
.mycard-list .mylist-main .card-active{
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.3);
    transform: scale(1.05);
    border-radius: 10px;
    overflow: hidden;
}
.mycard-list .mylist-main .card-active:hover{
    transform: none;
}
.setting-main{
    padding-top:0;
}
.setting-main .section-main{
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding: 35px 0;
}
.setting-data .tax-btn{
    margin-left: 40px;
}
.setting-data .row{
    border-bottom: none;
    padding-top: 0;
}

.setting-data .swtich-data {
    margin-bottom: 10px;
}
 .setting-data .custom-switch .custom-control-label::after {
    top: calc(5px + 2px);
    left: calc(-2.25rem + 3px);
} 
.setting-data .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(40px);
}
.setting-data .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 60px;
    height: 25px;
    pointer-events: all;
    border-radius: 15px;
} 
.tax-main .row .col-md-6 {
    margin-bottom: 15px;
}
.tax-main .row .col-md-6:nth-child(3),.tax-main .row .col-md-6:nth-child(4) {
    margin-bottom: 0;
}
.btn-update{
    margin-left: -15px;
}
.btn-update{
    margin-left: -15px;
}
.business-status{
    color: #00e723;
}
.business-closed-status{
    color: red;
}
.order-value{
    margin-bottom: 0 !important;
}
.btn-add{
    margin-left: 580px;
    margin-top: 15px;
}
.btn-remove{
    margin-top: 20px;
}
.btn-remove .bx{
    font-size: 17px;
    color: #fff;
}
.customer-app .application{
    margin-top: -15px;
}
#profile-upload{
    background-image:url('hht');
    background-size: cover;
    background-position: center;
    height: 140px;
    width: auto;
    border: 1px solid #bbb;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
}
#profile-upload:hover input.upload{
  display:block;
}
#profile-upload:hover .hvr-profile-img{
  display:inline-block;
 }
#profile-upload .fa{   margin: auto;
    position: absolute;
    bottom: -4px;
    left: 0;
    text-align: center;
    right: 0;
    padding: 6px;
   opacity:1;
  transition:opacity 1s linear;
   -webkit-transform: scale(.75); 
 
 
}
#profile-upload:hover .fa{
   opacity:1;
   -webkit-transform: scale(1); 
}
#profile-upload input.upload {
    z-index:1;
    left: 0;
    margin: 0;
    bottom: 0;
    top: 0;
    padding: 0;
    opacity: 0;
    outline: none;
    cursor: pointer;
    position: absolute;
    background:#ccc;
    width:100%;
    display:none;
}

#profile-upload .hvr-profile-img {
  width:100%;
  height:100%;
  display: none;
  position:absolute;
  vertical-align: middle;
  position: relative;
  background: transparent;
 }
#profile-upload .fa:after {
    content: "";
    position:absolute;
    bottom:0; left:0;
    width:100%; height:0px;
    background:rgba(0,0,0,0.3);
    z-index:-1;
    transition: height 0.3s;
    }

#profile-upload:hover .fa:after { height:100%; }



#profile-upload-flat{
    background-image:url('');
    background-size: cover;
    background-position: center;
    height: 140px;
    width: auto;
    border: 1px solid #bbb;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
}
#profile-upload-flat:hover input.upload{
  display:block;
}
#profile-upload-flat:hover .hvr-profile-img-flat{
  display:inline-block;
 }
#profile-upload-flat .fa{   margin: auto;
    position: absolute;
    bottom: -4px;
    left: 0;
    text-align: center;
    right: 0;
    padding: 6px;
   opacity:1;
  transition:opacity 1s linear;
   -webkit-transform: scale(.75); 
 
 
}
#profile-upload-flat:hover .fa{
   opacity:1;
   -webkit-transform: scale(1); 
}
#profile-upload-flat input.upload {
    z-index:1;
    left: 0;
    margin: 0;
    bottom: 0;
    top: 0;
    padding: 0;
    opacity: 0;
    outline: none;
    cursor: pointer;
    position: absolute;
    background:#ccc;
    width:100%;
    display:none;
}

#profile-upload-flat .hvr-profile-img-flat {
  width:100%;
  height:100%;
  display: none;
  position:absolute;
  vertical-align: middle;
  position: relative;
  background: transparent;
 }
#profile-upload-flat .fa:after {
    content: "";
    position:absolute;
    bottom:0; left:0;
    width:100%; height:0px;
    background:rgba(0,0,0,0.3);
    z-index:-1;
    transition: height 0.3s;
    }

#profile-upload-flat:hover .fa:after { height:100%; }

#colorpicker1 {
  width: 320px;
  height : 80px;
  border: none;
}

#colorpicker2 {
    width: 320px;
    height : 80px;
    border: none;
}
  
#colorpicker3 {
    width: 320px;
    height : 80px;
    border: none;
    margin-top: 30px;
}

#colorpicker4 {
    width: 320px;
    height : 80px;
    border: none;
    margin-top: 30px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.version-main p{
    color: #FF0000;
    font-weight: 600;
}
.text-main{
    width: 100%;
    text-align: center;
}
.settings-main .sections-main{
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding: 15px 0;
}
.rounded-circle-banner{
    border-radius: 5% !important;
}
.order-csv{
    margin-top: -9px;
}
.profile-summary-data{
    border-bottom: 2px solid #f6f6f6;
}
.customer-tab{
    margin-top: 40px;
}
.item-name-main{
    width: 100%;
    text-align: center;
}
.item-table-main td{
    white-space: -o-pre-wrap; 
    word-wrap: break-word;
    white-space: pre-wrap; 
    white-space: -moz-pre-wrap; 
    white-space: -pre-wrap; 

}
.item-table-main thead tr{
    white-space: nowrap;
}
.item-order-main {
    border: 2px solid #eff2f7;
}
.bold-text td:first-child{
        font-weight: 600;
}
.total-text h6{
    font-weight: 600;
}
#colorpickers1{
    height: 36px !important;
    width: 100% !important;
}
.category-image .dz-message{
    margin: 0 !important;
}
.table-main-border{
    border: 2px solid #eff2f7;
    margin-top: 26px;
}
.operation-tabs .tabs{
    margin-left: -35px;
}

.table-border{
    border: 2px solid #eff2f7;
}
.details-profile-main{
    margin-left: -33px;
}
.vendor-list-main{
    margin-top: -28px;
}
.vendor-list-main .table{
    margin-left: 0;
}
.months-main .multiselect{
    width: 15%;
    padding-right: 14px;
}
.year-main{
    margin-right: -52px;
    margin-bottom: 4px;
}
.months-main .mx-datepicker{
    width: 161px !important;
    margin-right: 46px !important;
}
.months-main .text-sm-right{
    width: 23%;
    margin-bottom: 2px;
}
.vendor-settlement{
    width: 104%;
    margin: -16px;
}
.payment-info .show{
    transform: none !important;
}
.driver-main-table{
    margin-top: 30px;
    margin-left: 3px;
}
.driver-operation-tabs{
    margin-left: -15px;
}
.delivery-zone-table{
    margin-left: 3px;
}
.delivery-search{
    padding-left: 15px;
}
.delivery-zone a{
    margin-left: -8px !important;
}
.catalog-main{
    display: flex;
    justify-content: space-between;
    border: 2px solid #eff2f7;
    flex-wrap: wrap;

}

.catalog-main .left-card{
    width: 100%;
    margin-top: 25px;
}
.catalog-item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #eff2f7;
    padding-bottom: 10px;
}
.menu-body{
    border: 2px solid #eff2f7;
    height: 736px;
    overflow: auto;
}
.menu-add .drag-column-header h4{
    padding-top: 8px;
    padding-left: 6px;
}
.menu-box .list-group{
    padding-top: 25px;
}
.menu-box .menu-add{
    border-bottom: 2px solid #eff2f7;
}
.menu-task p{
    margin-bottom: 0;
}
.menu-task .custom-switch .custom-control-label::after {
    top: calc(5px + 2px);
   /* left: calc(-2px + 1px);*/
} 
.menu-task .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    top: calc(5px + 2px);
    left: calc(-2.75rem + 1px);
} 
.menu-task .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(40px);
}
.menu-task .custom-switch .custom-control-label::after {
    width: calc(1.2rem - 4px);
    height: calc(1.2rem - 4px);
}
.menu-task .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 50px;
    height: 25px;
    pointer-events: all;
    border-radius: 15px;
} 
.menu-task .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #34c38f;
    background-color: #34c38f;
}
.menu-item-main .item-add{
    border-bottom: 2px solid #eff2f7;
}
.item-menu-main .drag-column-header h4{
    padding-top: 11px;
    padding-left: 6px;
}
.item-card{
    border-bottom: 2px solid #eff2f7;
}
/* .menu-left-main .col-lg-4{
    padding-left: 0;
    margin-top: 20px;
}
.menu-left-main .col-lg-8{
    padding-right: 0;
    margin-top: 20px;
} */
.line-task .list-inline{
    margin-left: -21px;

} 
.catalog-task-status{
    /*margin-top: 14px;*/
    margin-right: 5px;
}
.catalog-task-status ul{
    padding-top: 10px;
}
.menu-task .card{
    margin-bottom: 15px;
}
.progress-image img{
    width: 93%;
    height: 60px;
    border-radius: 7%;
}
.catalog-task-status ul li{
    float: right;
}
.catalog-task-status ul li:first-child{
    /*float: left;*/
    margin-left: 8px;
}
.catalog-task-status ul li:nth-child(even){
    margin-left: 8px;
}
.check-status{
    margin-top: 23px;
}
.menu-task .active {
    background-color: #2a3042;
}
.menu-task .active .title{
      color: #fff;
}
.order-list-main {
    margin: 12px 0 40px 0;
}
.order-list-tab{
    margin: 30px 0 40px 0;
}
.search-loader{
    height: 50vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #74788d;
}
.search-icon{
    text-align: center;
    font-size: 70px;
}
.search-loader h2{
    display: block;
    text-align: center;
    color: #74788d;
}
.operation-main ul{
    margin-bottom: 40px !important;
}
.plr-0{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.w-100 .float-right{
    color: #fff;
    background-color: #556ee6;
    border-color: #556ee6;
    font-weight: 400;
}
.catalog-item-status{
    border-top: 1px solid #eff2f7;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-top: 20px;
}
.vendor-img{
    border-radius: 15% !important;
    height: 50px;
    width: 50px;
}
.date-range-list{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.vendor-btn-main{
    text-align: right;
}
body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active
{
    color: #fff !important;
}
body[data-sidebar=dark].vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active i {
    color: #fff !important;
}
.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 27px;
  }
  
  .switch input {display:none;}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f46a6a;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  input:checked + .slider {
    background-color: #34c38f;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(55px);
    -ms-transform: translateX(55px);
    transform: translateX(55px);
  }
  
  /*------ ADDED CSS ---------*/
  .switch .on
  {
    display: none;
  }
  
  .switch .on, .switch .off
  {
    color: white;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    font-size: 10px;
    font-family: Verdana, sans-serif;
  }
  .switch .on {
    left: 30%; 
  }
  .switch .off {
    left: 70%;
  }
  
  input:checked+ .slider .on
  {display: block;}
  
  input:checked + .slider .off
  {display: none;}
  tr.b-table-row-selected:focus {
      outline:none;
      border: none !important;
  }
  @media (min-width: 768px) {
    .daterangepicker.show-ranges.show-weeknumbers, .daterangepicker.show-ranges{
        min-width:690px !important;
    }
  }
  /*--------- END --------*/
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
}
input.switch-on:checked + .slider:before {
    -webkit-transform: translateX(28px);
    -ms-transform: translateX(28px);
    transform: translateX(28px);
}
.table-responsive .border{
    border: 2px solid #eff2f7 !important;
    margin-top: 26px;
}
.table-responsive .laravel-vue-datatable-thead{
    color: #495057;
    background-color: #f8f9fa;
    border-color: #eff2f7;
}
.table-striped tbody tr:nth-of-type(odd){
    background-color: #fff !important;
}
.laravel-vue-datatable-tbody .laravel-vue-datatable-tbody-tr:hover{
    color: #495057 !important;
    background-color: #f8f9fa !important;
}
.order-table .mb-3 .col-md-3 select{
        display: none;
}
.table-main-list .table thead{
    color: #495057;
    background-color: #f8f9fa;
    border-color: #eff2f7;
}
.table-main-list .table{
    border: 2px solid #eff2f7;
    margin-top: 26px;
}
.table-main-list .table tbody tr:hover{
    color: #495057;
    background-color: #f8f9fa;
}
.banner-item-listing{
    padding-top: 25px;
}
.table-wrapper .btn-toolbar{
    display: none !important;
}
.table-responsive .sticky-table-header{
    height: 47px !important;
}
.table-rep-plugin .table-border{
    border: 2px solid #eff2f7 !important;
}
.table-rep-plugin .btn-group .btn-default{
    display: none;
}
.table-rep-plugin .btn-toolbar {
    display: none;
}
.table-rep-plugin{
    width: 100%;
}
.list-table-main .table-responsive{
    border: #fff;
}
.banner-list-table .table thead tr th[aria-colindex="3"] {
    width: 50%;
}
.system-log-list{
    margin-top:40px;
}
.chat-list li a{
    border-bottom: 1px solid #eff2f7;
}
.table-main-list .table tbody td{
    vertical-align: middle;
}
.vendor-btn{
    display: flex;
}
.vendor-btn a:first-child{
    margin-right: 5px;
}
.table-main-list .table thead th{
    white-space: nowrap;
}
.imagePreview{
    width: 100%;
    height: 180px;
    background-position: center center;
    background:url("../images/no-img.png");
    background-color:#fff;
    background-size: cover;
    background-repeat:no-repeat;
    display: inline-block;
}
.imagePreview img{
    height: 180px;
    width: 100%;
    object-fit: cover;
}
.upload-img-btn .img{
    width: 0px;
    height: 0px;
    overflow: hidden;
}
.upload-img-btn
{
  display:block;
  border-radius:0px;
  background: rgba(227,6,19,0.8);
  margin-top:-5px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.imgUp
{
  margin-bottom:15px;
}
.cuisine-img{
    display: block;
}
.drop{
    min-height: 180px !important;
}
.newDrop{
    min-height: 350px !important;
}
.proImag {
    height: 120px;
    width: 120px;
    object-fit: contain;
    border-radius: 20px;
    margin-right: -10px;
}
.toasted-container.top-right {
    right: 0 !important; 
}
.toasted-container .toasted{
    margin-right: 12px;
}
.api-text-list h6,.api-text-list .form-group{
    margin-bottom: 0;
}
.custom__tag {
    display: inline-block;
    padding: 3px 12px;
    background: #d2d7ff;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 10px;
    cursor: pointer;
}
.custom__remove {
    padding: 0;
    font-size: 10px;
    margin-left: 5px;
}
.content-page-main p{
    font-size: 13px !important;
}
.nav-tabs-main .tab-content{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.nav-tabs-main .col-12, .nav-tabs-main .col-8{
    padding: 0;
}

/*----- Dropzone popup css start ----- */

.plus_button {
    height: 120px;
    width: 120px;
    border: 1px solid #ccc;
    border-radius: 10%;
    cursor: pointer;
}
.plus {
    font-size: 93px;
    opacity: 0.5;
    color: gray;
}
.image_thumb {
    border-radius: 10% !important;
    height: 120px;
    width: 120px;
    object-fit: cover;
}
.remove {
    position: absolute;
    cursor: pointer;
    text-align: center;
    right: 3px;
    top: 3px;
    color: white;
    background: red;
    height: 20px;
    padding-top: 0px;
    border-radius: 50%;
    width: 20px;
    font-size: 16px;
    box-shadow: 0px 0px 5px black;
}
.relative {
    position: relative;
}
.dropzone.dz-clickable .dz-message{
    margin: 1em 0 !important;
}

/*----- Dropzone popup css end ----- */

.faq-list-card .card-body{
    min-height: 430px;
}
.faq-list-card .faq-box{
    border-bottom: 2px solid #eff2f7;
    margin: 20px 0;
    padding-bottom: 20px;
}
.faq-list-card .text-muted{
    margin-bottom: 0px !important;
    padding-right: 10px;
}
.faq-btn a{
    margin-right: 10px;
}
.vendor_icon .logo-lg img{
    height: 55px;
    object-fit: cover;
}
/* rating start */
.symbol {
    display: inline-block;
    border-radius: 50%;
    border: 5px double white;
    width: 30px;
    height: 30px;
}
  
.symbol-empty {
    background-color: #ccc;
}

.symbol-filled {
    background-color: black;
}
/* rating end */


.login-main {
    margin-top: -25px;
}

.reports-list-fill a{
    width: 40%;
}
.item-menu table tr td:nth-child(2){
    width: 67%;
}
.date-main-content.main-content{
    overflow: visible !important;
}
.report-card .card{
    min-height: 230px;
}
.order-menu thead tr th{
    color: #495057;
    background-color: #f8f9fa;
    border-color: #eff2f7;
}
.report-tag p{
    margin-top: 5px;
}
.home-layout img{
    width: 60%;
}
.business-app-image .image_thumb{
    object-fit: contain !important;
}
.category-search{
    margin-bottom: 10px;
}
.btn-group-example .btn:before {
    content: "OR";
    position: absolute;
    font-size: 10px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
    background-color: #74788d;
    color: #eff2f7;
    border: 1px solid #fff;
    left: -12px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}
.btn-group-example .btn:first-child:before{
    content: none !important;
}
.menu-type {
    margin-left: 2px;
}
/* .selling-time-slot .date-time{
    width: 100% !important;
} */
.dashboard-thirty a{
    color: #495057;
}
.icon-main-page .page-title-left{
    display: flex;
    align-items: center;
}
.avatar-main-icon .avatar-xs{
    margin-top: -28px;
}
.report-main-p p{
    margin-bottom: 0 !important;
}
body[data-layout=horizontal] .container-fluid, body[data-layout=horizontal] .container-sm, body[data-layout=horizontal] .container-md, body[data-layout=horizontal] .container-lg, body[data-layout=horizontal] .container-xl, body[data-layout=horizontal] .navbar-header {
    max-width: 96% !important;
}
.system-time-log{
    padding-top: 7px;
}
.system-time-log i{
    cursor: pointer;
}
.add_item_{
    margin: 5px;
}
.required:after {
    content:" *";
    color: red;
  }
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}
.avatar-logo-main .rounded-circle{
    overflow: hidden;
}
.avatar-logo-main .rounded-circle img{
    width: 100%;
    height: auto;
}
input#payment_link {
    cursor: pointer;
}
.vendor-table .table tbody tr{
    outline: none;
    cursor: pointer;
}
.driver-table .table tbody tr{
    outline: none;
    cursor: pointer;
}
.customer-table .table tbody tr{
    outline: none;
    cursor: pointer;
}
.vendor-status .switch{
    width: 77px;
    height: 32px;
    margin-bottom: 0;
}
.modfy-btns button{
    margin-right: 10px;
    height: 26px;
    width: 26px;
}
.vendor-status .switch .on {
    left: 36%;
}
.vendor-status input.switch-on:checked + .slider:before {
    left: 23px;
    bottom: 6px;
    height: 22px;
    width: 22px;
}
.vendor-status .switch .off {
    left: 65%;
}
.vendor-status .slider:before {
    left: 3px;
    bottom: 6px;
    height: 22px;
    width: 22px;
}
.cust {
    float: right;
}
.main-content{
    overflow: visible !important;
}
.cod-online-order:first-child{
    padding-left: 13px;
}
.cod-online-order:last-child{
    width: 25%;
    padding-right: 5px;
}
.cod-online-order:nth-child(4){
    width: 25%;
}
.cod-online-order {
    width: 14%;
}
.cod-online-order:nth-child(4) .form-group .date-range-list .vue-daterange-picker .reportrange-text{
    padding:0 10px!important;
    display: flex;
    align-items: center;
}
.sales-report {
    width: 13%;
}
.sales-report:first-child {
    padding-left: 13px;
}
.sales-report:last-child {
    width: 24%;
}
.sales-report:last-child .form-group .date-range-list .vue-daterange-picker .reportrange-text {
    padding:0 10px!important;
    display: flex;
    align-items: center;
}
/*.cod-online-order:first-child .form-group .date-range-list select, .cod-online-order:nth-child(2) .form-group .date-range-list select, .cod-online-order:nth-child(3) .form-group .date-range-list select{
    width: 172px;
}*/
.gst-delivery-change-report{
    border-right: 1px solid #ddd;
    color: #000;
    font-size: 12px;
    font-weight: 500;
}
.topnav{
    margin-top: 0;
}
.promo-code-reports .form-group{
    margin-left: 52px;
}
.veg {
    position: absolute;
    left: 78px;
    top: 23px;
    height: 17px;
    width: 17px;
    border: solid 2px green;
    background: #ffffff;
    padding: 0px;
}
.veg span {
    height: 8px;
    width: 7px;
    background: green;
    border-radius: 6px;
    display: inline-block;
    margin: 3px;
    padding: 0px;
}
.non-veg {
    position: absolute;
    left: 78px;
    top: 23px;
    height: 17px;
    width: 17px;
    border: solid 2px red;
    background: #ffffff;
    padding: 0px;
}
.non-veg span {
    height: 8px;
    width: 7px;
    background: red;
    border-radius: 6px;
    display: inline-block;
    margin: 3px;
    padding: 0px;
}
.custmize {
    margin: 0px 27px 0px 0;
}
.download {
    margin: 0 0 14px 12px
}
.business-logo{
    margin-left: -10px;
}
.total-sales-earning .date-range-list{
    margin-left: 63px;
}

/* Table Sortable */
.vendor-table .table thead th:first-child,.vendor-table .table thead th:nth-child(3),.vendor-table .table thead th:nth-child(4),.vendor-table .table thead th:nth-child(6),.vendor-table .table thead th:nth-child(7){
    pointer-events: none;
}
.driver-table .table thead th:first-child,.driver-table .table thead th:nth-child(5),.driver-table .table thead th:nth-child(7),.driver-table .table thead th:nth-child(8){
    pointer-events: none;
}
.vendor-category .table thead th:first-child,.vendor-category .table thead th:nth-child(3),.vendor-category .table thead th:nth-child(4){
    pointer-events: none;
}
.zone-list-table .table thead th:nth-child(1),.table thead th:nth-child(3){
    pointer-events: none;
}
.delivery-zone-table .table thead th:nth-child(5){
    pointer-events: none;
}
.m-order-main .table-responsive{
    border: none;
}
.catalog-item .order-tabs{
    margin-top: -17px;
}
.del-zone{
    margin: 0 0 0 581px
}
.opt-time{ 
    background-color: #f8f9fa
}
.table-nowrap th {
    white-space: normal !important;
}
.menu-items {
    height: 95px;
}
.chart-loader .is-loading{
    position: relative;
}
.chart-loader .preloader-component{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 999;
    width: 100%;
}
.chart-loader .status{
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
}
.chart-loader .preloader-component{
    display: none;
}
.chart-loader .status{
    display: none;
}
.calendars{
    flex-wrap: nowrap !important;
}
/* .side-menu-ul {
    height: calc(100vh - 130px);
    overflow-y: scroll;
    position: relative;
}
.side-menu-ul::-webkit-scrollbar {
    width: 5px;
    border-radius:5px;
}
.side-menu-ul::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 10px;
}
.side-menu-ul::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    outline: none;
    border-radius:5px;
  } */

  /// new code added below
  .infodetail {
    color: red;
  }
  
  .defaulturl {
    background-color: #e9ecef !important;
  }
  
  .home-page-image .business-logo .image_thumb {
    border-radius: 5% !important;
    height: 170px !important;
    width: 300px !important;
  }
  
  .cust-accordian {
    a {
      &.collapsed {
        box-shadow: none;
      }
  
      &.not-collapsed {
        box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
      }
    }
  
    .card {
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0 !important;
  
      &:first-child {
        border-top-left-radius: 0.25rem !important;
        border-top-right-radius: 0.25rem !important;
      }
  
      &:last-child {
        border-bottom-left-radius: 0.25rem  !important;
        border-bottom-right-radius: 0.25rem  !important;
      }
    }
  
    a.not-collapsed .card-header {
      background-color: #eef1fd !important;
  
      h6 {
        color: #4d63cf;
        position: relative;
      }
    }
  
    .card-header {
      background-color: #fff !important;
      padding: 20px;
    }
  
    a {
      &.collapsed h6::after {
        -ms-flex-negative: 0;
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        margin-left: auto;
        content: "";
        background-image: url('/public/images/custangle-down.svg');
        background-repeat: no-repeat;
        background-size: 16px;
        -webkit-transition: -webkit-transform .2s ease-in-out;
        transition: -webkit-transform .2s ease-in-out;
        transition: transform .2s ease-in-out;
        transition: transform .2s ease-in-out,-webkit-transform .2s ease-in-out;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
  
      &:not(.collapsed) h6::after {
        background-image: url('/public/images/custangle-top.svg') !important;
        transform: rotate(180deg) !important;
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        z-index: 999;
        width: 16px;
        height: 16px;
      }
    }
  }